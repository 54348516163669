import React, { createRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Layout, Button, Form, Input, Card, Row, Col, Divider } from "antd";

import "../styles/home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faBurger,
  faCartShopping,
  faBolt,
  faGift,
  faBoxesPacking,
  faPhone,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

import {
  faClock,
  faCalendarDay,
  faMapLocationDot,
  faMoneyBill1,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const { Content } = Layout;
const { Meta } = Card;

const merchants = [
  { src: "/Dhaka-Republic.jpg", alt: "dhaka republic" },
  { src: "/Dhakaiya-Koreana.png", alt: "dhakaiya koreana" },
  { src: "/eurosiabd.jpg", alt: "eurosia" },
  { src: "/Happy-Paws-Pet-Shop.png", alt: "happy paws" },
  { src: "/Khati-organic-food.jpg", alt: "khati organic" },
  { src: "/Muntaha-Fashion-House.jpg", alt: "muntaha fashion" },
  { src: "/Palate.jpg", alt: "palate" },
  { src: "/Spice-Hues.png", alt: "spice hues" },
  { src: "/Tanzila-Haider-Couture.jpg", alt: "tanzila haider couture" },
  { src: "/The-Label.png", alt: "the label" },
  { src: "/Totem-BD.jpg", alt: "vs mask" },
  { src: "/VS-Mask.png", alt: "vs mask" },
];

function HomePage() {
  const [form] = Form.useForm();
  const orderRef = createRef();
  const phoneRef = createRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleTracking = async (values) => {
    const response = await axios.get(
      `https://t.amvinesbd.com/tracking?orderId=${values.order_id}&cmn=${values.mobile_number}`
    );
    console.log(response);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Content
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        marginBlock: "none",
        paddingInline: "3vw",
        paddingBlock: "3vw",
        // marginBottom: "none",
        // outline: "auto",
      }}
    >
      <div className="container">
        <div className="hero-wrapper">
          <div className="text-wrapper-hero">
            <h2 style={{ fontWeight: "bold", color: "#820374" }}>
              Amvines Logistics is your ultimate delivery solution
            </h2>
            <h1 style={{ color: "#e7771c" }}>YOUR DELIVERY PARTNER</h1>
            <Button
              type="primary"
              style={{
                fontWeight: "bold",
                backgroundColor: "#820374",
                width: "180px",
              }}
            >
              Become our Merchant
            </Button>
          </div>
          <img
            src="/Amvinesweb.svg"
            alt="Amvines logo"
            className="delivery-man"
            style={{ fill: "#820374" }}
          />
        </div>
        <div className="track-wrapper">
          <h1>Track Your Parcel</h1>
          <Form
            form={form}
            name="horizontal_login"
            layout="block"
            onFinish={handleTracking}
            className="track-form"
          >
            <Form.Item name="order_id">
              <Input placeholder="PARCEL ORDER ID" />
            </Form.Item>
            <Form.Item name="mobile_number">
              <Input placeholder="MOBILE NUMBER" />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => (
                <Button type="primary" className="btn" htmlType="submit">
                  Track
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
        <div className="delivery-wrapper">
          <h1>What we Deliver</h1>

          <div
            style={{
              color: "gray",
              fontSize: "1.25rem",
              paddingBlock: "2em",
              // paddingTop: "4vw",
              borderColor: "black",
            }}
          >
            Some kind of product we deliver to our respected client
          </div>
          <hr />
        </div>
        <Row
          gutter={20}
          style={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={
                <FontAwesomeIcon className="custom-icon" icon={faFileLines} />
              }
            >
              Document
            </Card>
          </Col>

          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={
                <FontAwesomeIcon
                  className="custom-icon"
                  icon={faBoxesPacking}
                />
              }
            >
              Package
            </Card>
          </Col>

          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={
                <FontAwesomeIcon className="custom-icon" icon={faBurger} />
              }
            >
              Food
            </Card>
          </Col>

          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={
                <FontAwesomeIcon
                  className="custom-icon"
                  icon={faCartShopping}
                />
              }
            >
              Grocery
            </Card>
          </Col>
          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={<FontAwesomeIcon className="custom-icon" icon={faBolt} />}
            >
              Electronics
            </Card>
          </Col>
          <Col xs={24} lg={4} md={4} sm={12} style={{ marginTop: "10px" }}>
            <Card
              hoverable
              cover={<FontAwesomeIcon className="custom-icon" icon={faGift} />}
            >
              Gift
            </Card>
          </Col>
        </Row>

        <div className="about-wrapper">
          <h1>Impossible is not an option!</h1>
          <p
            style={{
              fontWeight: "bold",
              paddingBlock: "3em",
              paddingTop: "1.25em",
            }}
          >
            Need to deliver a parcel? Choose Amvines!
          </p>
          <Row
            gutter={16}
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon className="custom-icon" icon={faClock} />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  1 Hour delivery
                </p>
                <p style={{ fontSize: "1rem" }}>
                  We do one-hour delivery with the best Price into the same
                  city.
                </p>
              </Card>
            </Col>
            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon
                    className="custom-icon"
                    icon={faCalendarDay}
                  />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  Same Day Delivery
                </p>
                <p style={{ fontSize: "1rem" }}>
                  our same day delivery service offers fast & secure delivery to
                  your door step.
                </p>
              </Card>
            </Col>

            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon
                    className="custom-icon"
                    icon={faMoneyBill1}
                  />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  Regular Price, Premium Service
                </p>
                <p style={{ fontSize: "1rem" }}>
                  We offer premium service with regular price for parcel
                  delivery.
                </p>
              </Card>
            </Col>

            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon className="custom-icon" icon={faHandshake} />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  0% COD Inside Dhaka
                </p>
                <p style={{ fontSize: "1rem" }}>
                  COD charge inside Dhaka 0%, outside Dhaka 1%.
                </p>
              </Card>
            </Col>
            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  marginTop: "1em",
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon
                    className="custom-icon"
                    icon={faMapLocationDot}
                  />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  Real Time Monitoring System
                </p>
                <p style={{ fontSize: "1rem" }}>
                  We ensure Door to door real-time goods tracking service from
                  anywhere in Bangladesh.
                </p>
              </Card>
            </Col>

            <Col className="gutter-row" lg={6} md={8} sm={12}>
              <Card
                // hoverable
                style={{
                  width: 220,
                  // outline: "auto",
                }}
                cover={
                  <FontAwesomeIcon className="custom-icon" icon={faPhone} />
                }
              >
                <p style={{ fontWeight: "500", fontFamily: "cursive" }}>
                  Our Support
                </p>
                <p style={{ fontSize: "1rem" }}>
                  24 Customer Service Reliable Customer Service to Manage All
                  Queries.
                </p>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="about-extra">
          <div className="word-container">
            <p>Why we are?</p>
            <div className="word-line1">Secure way to deliver</div>
            <div className="word-line2">Smart way to deliver</div>
          </div>
        </div>
        <div className="stats-cards" style={{ marginTop: "2.5em" }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={16} sm={7} md={6} lg={7} style={{ margin: "1em" }}>
              <Card
                data-aos="zoom-in"
                style={{
                  textAlign: "center",
                  boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
                }}
                cover={
                  <img
                    alt="example"
                    src="./fast-delivery.png"
                    style={{
                      width: "100px",
                      margin: "auto",
                      paddingTop: "1.5em",
                    }}
                  />
                }
              >
                <p
                  style={{
                    fontWeight: "bolder",
                    marginBlock: "none",
                    paddingBlock: "none",
                  }}
                >
                  25k Parcel Delivered
                </p>
                {/* <Meta style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} title="25K Parcel Delivered" /> */}
              </Card>
            </Col>
            <Col xs={16} sm={7} md={6} lg={7} style={{ margin: "1em" }}>
              <Card
                data-aos="zoom-in"
                style={{
                  textAlign: "center",
                  boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
                }}
                cover={
                  <img
                    alt="example"
                    src="./warehouse.png"
                    style={{
                      width: "100px",
                      margin: "auto",
                      paddingTop: "1.5em",
                    }}
                  />
                }
              >
                <p
                  style={{
                    fontWeight: "bolder",
                    marginBlock: "none",
                    paddingBlock: "none",
                  }}
                >
                  70+ Total Hub
                </p>
              </Card>
            </Col>
            <Col xs={16} sm={7} md={6} lg={7} style={{ margin: "1em" }}>
              <Card
                data-aos="zoom-in"
                style={{
                  textAlign: "center",
                  boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
                }}
                cover={
                  <img
                    alt="example"
                    src="./satisfaction.png"
                    style={{
                      width: "100px",
                      margin: "auto",
                      paddingTop: "1.5em",
                    }}
                  />
                }
              >
                <p
                  style={{
                    fontWeight: "bolder",
                    marginBlock: "none",
                    paddingBlock: "none",
                  }}
                >
                  250k Satisfied Clients
                </p>
              </Card>
            </Col>
            <Col xs={16} sm={7} md={6} lg={7} style={{ margin: "1em" }}>
              <Card
                data-aos="zoom-in"
                style={{
                  textAlign: "center",
                  boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
                }}
                cover={
                  <img
                    alt="example"
                    src="./delivered.png"
                    style={{
                      width: "100px",
                      margin: "auto",
                      paddingTop: "1.5em",
                    }}
                  />
                }
              >
                <p
                  style={{
                    fontWeight: "bolder",
                    marginBlock: "none",
                    paddingBlock: "none",
                  }}
                >
                  500k delivery Packages
                </p>
              </Card>
            </Col>
          </Row>
        </div>
        <div
          className="merchants-wrapper"
          style={{
            margin: "auto",
            marginBlock: "8vh",
            textAlign: "center",
          }}
        >
          <h1>Our Valued Merchants</h1>
          <p style={{ fontWeight: "bold", color: "#E7771C" }}>
            We provide excellent service to all our customers to further our
            relationship to a next phase in business activities.
          </p>
          <div
            className="carousel"
            style={{ marginTop: "4em", marginInline: "3em" }}
          >
            <Carousel responsive={responsive}>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./rokomari.png" alt="rokomari" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./Aiim-Gloval.png" alt="Aiim" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./Deximop-Internation-Limited.png" alt="Deximop" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./The-capital-Fan-Co.png" alt="Fan" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./The-Mall-BD.png" alt="Mall" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./USB-Bangla-Group.png" alt="USB" />
              </div>
              <div
                style={{
                  width: "220px",
                  marginRight: "3.5em",
                  border: "3px solid black",
                }}
              >
                <img src="./fashion.png" alt="fashion" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="story-wrapper">
          <h1>Our Story</h1>
          <Divider />
          {/* <p>
            Amvines Logistics Ltd., founded in 2020, provides logistic management
            services to local merchants in Bangladesh.
          </p> */}
          <div className="story-text">
            <hr />
            <img src="/amvinesStickerLogo.png" alt="amvines logo" className="our-store-img"/>
            <p>
              Amvines is a Bangladesh base leading e-commerce platform &
              nationwide leading technology -empowered Logistics service
              provider accessible to all. We are offering a fast, secure and
              convenient online shopping experience with a broad product
              offering in categories ranging from Fresh Grocery,Imported Foods,
              Ready-to-eat confectionery,Beauty Care,Imported Watch,consumer
              Health Care to Kids Care.We offer the best selection of quality
              products you'll find anywhere all at your fingertips. Our believe
              in the transformative power of technology and want to change the
              world for the better by providing a platform to connect buyers and
              sellers within one community.Easy shopping solution by acess
              Internet users across the region, Amvines offers a one-stop online
              shopping experience that provides a wide selection of products, a
              social community for exploration, and seamless fulfilment
              services.
            </p>
          </div>
        </div>
        <div className="merchant-wrapper">
          {/* <div className="img-text-wrapper"> */}
          <div className="text-wrapper">
            <p className="text-wrapper-h1">We're Waiting To Help You</p>
            <p className="text-wrapper-p">
              Get in touch with us today and let’s start transforming your
              business from the ground up
            </p>
          </div>
          <div>
            <Button className="become-btn" style={{ color: "white " }}>
              <a href="https://merchant.amvinesbd.com/login">
                Become our merchant
              </a>
            </Button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Content>
  );
}

export default HomePage;
