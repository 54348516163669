import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../styles/deleteaccount.css";

const DeleteAccount = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    const data = {};

    formData.forEach((value, key) => {
      data[key] = value;
    });

    console.log("Form Data:", data);

    const userTextLabels = [
      "I have thoroughly enjoyed my experience with Amvines Logistics, but due to personal reasons, I have decided to discontinue my use of the app. In light of this, I kindly request that you proceed with the deletion of my account and associated data from your system.",
      "I understand that account deletion is an irreversible process, and I am fully aware that all my data, including profile information and any associated content, will be permanently removed. I have taken all necessary steps to ensure that I have backup copies of any information that I may need in the future.",
      "Thank you for your understanding, and I look forward to a smooth account deletion process.",
    ];

    userTextLabels.forEach((label, index) => {
      data[`user_text_${index + 1}`] = label;
    });

    emailjs
      .sendForm(
        "service_kb1sbav",
        "contact_form",
        form.current,
        data,
        "NDVMobLASLmcU-ENZ"
      )
      .then(
        ((result, form) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        })
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <h2 style={{ textAlign: "center", marginTop: "0", marginBottom: "25px" }}>
        Account Deletion Request Form
      </h2>

      <label>Name</label>
      <input type="text" name="user_name" required />
      <label>Email</label>
      <input type="email" name="user_email" required />
      <label>Phone</label>
      <input type="tel" name="user_phone" required />
      <label style={{ fontWeight: "bold" }}>
        Note: Please ensure you have correctly input your information. This
        information will be used to verify the credibility of your ownership.
      </label>

      <label>
        I have thoroughly enjoyed my experience with Amvines Logistics, but due
        to personal reasons, I have decided to discontinue my use of the app. In
        light of this, I kindly request that you proceed with the deletion of my
        account and associated data from your system.
      </label>

      <label>
        I understand that account deletion is an irreversible process, and I am
        fully aware that all my data, including profile information and any
        associated content, will be permanently removed. I have taken all
        necessary steps to ensure that I have backup copies of any information
        that I may need in the future.
      </label>

      <label>
        Thank you for your understanding, and I look forward to a smooth account
        deletion process.
      </label>
      <button type="submit">Appeal</button>
    </form>
  );
};

export default DeleteAccount;
