import { Layout, Menu, Button } from "antd";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import "../styles/home.css";

const { Header, Content, Footer } = Layout;
// const items = [
//   { key: "home", label: "Home", link: "/" },
//   { key: "pricing", label: "Pricing", link: "/pricing" },
//   { key: "QnA", label: "Q&A", link: "/QnA" },
//   { key: "contact", label: "Contact Us", link: "/contact" },
// ];

// const menuItems = items.map((item) => (
//   <Menu.Item key={item.key}>
//     <Link to={item.link}>{item.label}</Link>
//   </Menu.Item>
// ));

function HomeLayout() {
  return (
    <>
      <Layout
        id="top"
        style={{
          display: "flex",
          flexDirection: "column",
          // minHeight: "100vh",
          // width: "100%",
          // alignItems: "center",
        }}
      >
        <Navbar />
        <Content>
          <Outlet />
        </Content>
        <Footer className="footer">
          <header
            className="footer-header"
            style={
              {
                // marginBlock: "0em",
                // outline: "auto",
              }
            }
          >
            <img
              src="/amvines-logo.png"
              alt="amvines logo"
              className="footer-img"
            />
            <p className="footer-left-text">
              Amvines logistics is the trusted platform that provides parcel
              delivery system with users being able to track.
            </p>
          </header>
          <aside>
            <ul className="category">
              <li>
                <h3>Quick Links</h3>
              </li>
              <li>
                <Link to="/" style={{ color: "black" }}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/qna" style={{ color: "black" }}>
                  Q&A
                </Link>
              </li>
              <li>
                <Link to="/contact" style={{ color: "black" }}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" style={{ color: "black" }}>
                  Privacy Policy
                </Link>
              </li>
              <li></li>
            </ul>
            {/* <ul className="category">
              <li>
                <h3>Company</h3>
              </li>
              <li>Objective</li>
              <li>Capital</li>
              <li>Security</li>
              <li>Selling</li>
              <Button
                type="link"
                href="./delete-account"
                danger
                style={{ margin: 0, padding: 0 }}
              >
                Delete account
              </Button>
            </ul> */}
          </aside>
        </Footer>
      </Layout>
    </>
  );
}

export default HomeLayout;
